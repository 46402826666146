import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { concatClassNames as cn } from "@sys42/utils";

import {
  HighlightedText,
  Faqs,
  featureOverviewFeatures,
} from "@userbrain/website-ui";

import Layout from "../../layouts/layout";
import { FeatureOverview } from "../../components/FeatureOverview";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderOverlap } from "../../components/HeaderOverlap";
import { Slider } from "../../components/Slider";
import { TaskTypes } from "../../components/TaskTypes";
import { ThreeColumnsIcons } from "../../components/ThreeColumnsIcons";

import * as helperStyles from "../helpers.module.css";
import * as styles from "./styles/easy-setup.module.css";

import imageIconEasySetup from "@userbrain/website-ui/images/feature-icon-start.svg";
import imgHandWithComputerMouse from "../../images/lineIcons/apply-and-take-qualification-test.svg";
import imgTemplatesAndTasks from "../../images/lineIcons/templates-and-tasks.svg";
import imgNoCreditCard from "../../images/lineIcons/no-credit-card-needed.svg";
import imageSetUpUserTest from "../../images/dashboardSetUpUserTest.jpg";
import imageShowTemplates from "../../images/dashboardShowTemplates.jpg";
import imageSelectTemplate from "../../images/dashboardSelectTemplate.jpg";

import imageTaskTypeTask from "../../images/taskTypes/task.png";
import imageTaskTypeRatingScale from "../../images/taskTypes/ratingScale.png";
import imageTaskTypeMultipleChoice from "../../images/taskTypes/multipleChoice.png";
import imageTaskTypeSingleChoice from "../../images/taskTypes/singleChoice.png";
import imageTaskTypeWrittenResponse from "../../images/taskTypes/writtenResponse.png";
import imageTaskTypeRedirect from "../../images/taskTypes/redirect.png";

export default function EasySetup() {
  return (
    <Layout
      activeMenuItem={"features/easy-setup"}
      title={"Easy setup"}
      metaDescription={
        "Getting set up with Userbrain is quick & easy. Get started right away with our free trial, easily set up your first user tests with templates & task types. No credit card required."
      }
    >
      <article>
        <HeaderOverlap
          isDisplayCta
          featureIcon={imageIconEasySetup}
          featureIconAlt={"Spaceship"}
          overline={"Fast & easy"}
          title={
            <>
              Remote user testing{" "}
              <span className={helperStyles.h1Serif}>
                for websites, apps, and prototypes
              </span>
            </>
          }
          titleWidth={"13em"}
          buttonSubtext={"No credit card required"}
          staticImage={(className) => (
            <StaticImage
              src={"../../images/dashboardSelectTestType.jpg"}
              className={className}
              alt={
                "Test instruction editor for unmoderated remote user testing"
              }
            />
          )}
        />

        <div className={helperStyles.container}>
          <ThreeColumnsIcons
            className={helperStyles.spacingMd}
            title={"Fast & easy user testing"}
            col1Image={imgHandWithComputerMouse}
            col1Alt={"Hand clicking on a computer mouse"}
            col1Heading={"Free trial"}
            col1Text={
              "We want you to start testing your product fast, often, and imperfectly. With our free trial you can get started right away."
            }
            col2Image={imgTemplatesAndTasks}
            col2Alt={"Task list"}
            col2Heading={"Templates & tasks"}
            col2Text={
              "Get started with preset templates for ecommerce, booking, and more. Choose different task types for users to complete."
            }
            col3Image={imgNoCreditCard}
            col3Alt={"OK gesture"}
            col3Heading={"No credit card"}
            col3Text={
              "Our free trial doesn’t require a credit card. Try Userbrain and choose your perfect pricing plan once you’re ready."
            }
          />
        </div>

        <Slider
          className={helperStyles.spacingLg}
          title={<>Templates to make your&nbsp;life&nbsp;easier</>}
          subtitle={"How to use them:"}
          slides={[
            {
              title: "Set up a user test.",
              imageSrc: imageSetUpUserTest,
              imageAlt:
                "Test instruction editor first step: Set test name, url and language",
            },
            {
              title: (
                <>
                  Go to <i>Test instructions</i> and select{" "}
                  <i>Show templates.</i>
                </>
              ),
              imageSrc: imageShowTemplates,
              imageAlt:
                "Test instruction editor second step: Click on show templates",
            },
            {
              title: (
                <>
                  Choose between <i>Default</i>, <i>E-commerce</i>,{" "}
                  <i>Booking</i>, <i>SaaS</i> and <i>Company</i>.
                </>
              ),
              imageSrc: imageSelectTemplate,
              imageAlt: "Test instruction editor third step: Select a template",
            },
          ]}
        />

        <div className={helperStyles.container}>
          <TaskTypes
            className={cn(helperStyles.spacingMd, styles.taskTypes)}
            title={"Task types you can choose from:"}
            taskTypes={[
              {
                imageSrc: imageTaskTypeTask,
                imageAlt: "Lines of text",
                title: "Task",
                description:
                  "Describe what you want user testers to do in long-form written text.",
              },
              {
                imageSrc: imageTaskTypeRatingScale,
                imageAlt: "Star",
                title: "Rating scale",
                description:
                  "Let them give a star rating, or create your very own scale.",
              },
              {
                imageSrc: imageTaskTypeMultipleChoice,
                imageAlt: "Checked checkbox",
                title: "Multiple choice",
                description: "Let them choose more than one answer.",
              },
              {
                imageSrc: imageTaskTypeSingleChoice,
                imageAlt: "Selected radio button",
                title: "Single choice",
                description: "Give them one choice only.",
              },
              {
                imageSrc: imageTaskTypeWrittenResponse,
                imageAlt: "A big and a small letter",
                title: "Written response",
                description: "Collect written responses.",
              },
              {
                imageSrc: imageTaskTypeRedirect,
                imageAlt: "An arrow pointing to the right",
                title: "Redirect",
                description: "Direct them to another page.",
              },
            ]}
          />

          <Faqs
            className={cn(helperStyles.spacingLg, styles.faqs)}
            questions={[
              {
                question: "What can I test?",
                answer:
                  "If you can link to it, you can test it. Test an array of live websites, those under development, early prototypes, or even competitor's sites. Our testers use either their smartphones, desktops, or tablet devices.",
              },
              {
                question: "When will I receive the test videos?",
                answer:
                  "The delivery of tests is affected by a few factors. Depending on the time of day, the device type, and the language, the response time may vary. ",
              },
              {
                question: "What if I’m not happy with the video I received?",
                answer:
                  "We offer a 100% satisfaction guarantee. This means that if you’re not happy with a test, we’ll send you a new one.",
              },
              {
                question: "How many task types can I assign?",
                answer:
                  "You can assign and combine as many task types as you like. User tests carried out with one credit do, however, have a 20 minute time limit, so we recommend selecting anywhere between 3-5 task types for the test to be doable.",
              },
              {
                question: "I’m not sure what task type to assign…",
                answer:
                  "If you’re not sure what task type to assign, flick through and filter our examples for inspiration on what might suit your user test best.",
              },
              {
                question: "How do I use the different task types?",
                answer: (
                  <>
                    Check out these articles for a detailed description on:
                    <ul>
                      <li>
                        <a href="https://help.userbrain.com/help/how-to-use-rating-scales">
                          How to use rating scales
                        </a>
                      </li>
                      <li>
                        <a href="https://help.userbrain.com/help/how-to-use-single-and-multiple-choice-questions">
                          How to use single and multiple-choice questions
                        </a>
                      </li>
                      <li>
                        <a href="https://help.userbrain.com/help/how-to-use-written-responses">
                          How to use written responses
                        </a>
                      </li>
                      <li>
                        <a href="https://help.userbrain.com/help/how-to-use-redirects">
                          How to use redirects
                        </a>
                      </li>
                    </ul>
                  </>
                ),
              },
            ]}
          />

          <GetStartedBox
            className={helperStyles.spacingLg}
            titleWidth={"12.5em"}
            title={
              <>
                You want <HighlightedText cursor>fast and easy</HighlightedText>{" "}
                user testing in minutes?
              </>
            }
          />
        </div>
        <div
          className={cn(
            helperStyles.container,
            helperStyles.container_featureOverview
          )}
        >
          <FeatureOverview
            className={helperStyles.spacingLg}
            title={
              "Userbrain features for easy, unmoderated remote user testing"
            }
            titleWidth={"16em"}
            features={featureOverviewFeatures}
          />
        </div>
      </article>
    </Layout>
  );
}
