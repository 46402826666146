import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
import { Headline2Sans, Headline4Sans } from "@userbrain/website-ui";
import * as styles from "./styles.module.css";

export function TaskTypes(props) {
  const { className, title, taskTypes, ...containerProps } = props;

  return (
    <div className={cn(className, styles.taskTypes)} {...containerProps}>
      <Headline2Sans className={styles.title}>{title}</Headline2Sans>
      <div className={styles.taskTypesItems}>
        {taskTypes.map((item, index) => (
          <div className={styles.item} key={index}>
            <img
              className={styles.itemImage}
              src={item.imageSrc}
              alt={item.imageAlt}
            />
            <div>
              <Headline4Sans className={styles.itemTitle}>
                {item.title}
              </Headline4Sans>
              <p className={styles.itemDescription}>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
